<template>
  <tp-modal name="modal-utm-builder" width="550px">
    <v-card slot-scope="props">
      <v-card-title class="py-2 px-3 font-weight-bold">
        UTM Builder

        <v-spacer></v-spacer>

        <v-btn icon @click="handleCloseModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <div class="py-2 px-5">
        <form-utm-builder :url="props.payload"></form-utm-builder>
      </div>
    </v-card>
  </tp-modal>
</template>

<script>
import FormUtmBuilder from "./components/FormUtmBuilder.vue";

export default {
  components: {
    FormUtmBuilder
  },

  methods: {
    handleCloseModal() {
      this.$modal.hide({ name: "modal-utm-builder" });
    }
  }
};
</script>
